/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 7/7/2021
 * @Example
 */

import { Button, Typography, Divider, Checkbox, TextInput, DatePicker } from 'diginet-core-ui/components';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import Config from '../../../../config';
import { useStyles } from './styles';
import { Row, Col } from 'react-bootstrap';
import GridContainer from '../../../grid-container/grid-container';
import DropDownOrganization from '../../../common/dropdown/dropdown-treeview';
import Modal from '../../../common/modal/modal';
import { Column } from 'devextreme-react/data-grid';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import Icon from 'diginet-core-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import * as W29F2017Actions from '../../../../redux/W2X/W29F2017/W29F2017_actions';
import { useDispatch } from 'react-redux';
import { LoadPanel } from 'devextreme-react';

const W29F2017 = (props) => {
    const { open, handleOpenW29F2017 } = props;

    const [minimum, setMinimum] = useState(false);
    const [isShowSelected, setIsShowSelected] = useState(false);
    const [attendanceDateFrom, setAttendanceDateFrom] = useState(null);
    const [attendanceDateTo, setAttendanceDateTo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataGrid, setDataGrid] = useState(() => ({ rows: [], total: 0 }));
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const classes = useStyles({
        minimum,
    });

    const dispatch = useDispatch();

    const filter = useRef({
        Employee: '',
        OrgchartID: [],
        AttendanceDateFrom: null,
        AttendanceDateTo: null,
        Skip: 0,
        Limit: 20,
    });
    const gridRef = useRef(null);
    const selectedRowDatas = useRef(null);
    const dataSource = useRef(null);
    const changePage = useRef(false);
    const changeShow = useRef(false);

    const loadData = () => {
        const params = {
            FormID: 'W29F2017',
            Language: Config?.language ?? '84',
            ...filter.current,
            OrgchartID: JSON.stringify(filter.current?.OrgchartID ?? []),
        };
        setLoading(true);
        dispatch(
            W29F2017Actions.getData(params, (err, data) => {
                setLoading(false);
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup.show('ERROR', msg);
                }
                if (data) {
                    dataSource.current = data;
                    if (!isShowSelected) {
                        setDataGrid(data);
                    }
                }
            })
        );
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const search = () => {
        changePage.current = true;
        filter.current.Skip = 0;
        loadData();
    };

    const collapsePanel = (flag) => {
        if (typeof flag !== 'undefined') {
            setMinimum(!!flag);
        } else {
            setMinimum((minimum) => !minimum);
        }
    };

    const changeTypeShow = (e) => {
        if (!e) return false;
        changePage.current = true;
        changeShow.current = true;
        const value = e?.value ?? false;
        setIsShowSelected(value);
    };

    useEffect(() => {
        if (!isShowSelected) {
            const currentPage = Math.floor((filter.current?.Skip ?? 0) / (filter.current?.Limit ?? 20));
            setTimeout(() => {
                const paging = gridRef.current?._paging;
                if (paging) {
                    paging.onChangePage(currentPage);
                }
            }, 0);
        }
        const _data = isShowSelected ? { rows: selectedRowDatas.current ?? [], total: selectedRowDatas.current?.length ?? 0 } : dataSource.current;
        setDataGrid(_data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowSelected]);

    const handleChange = (key, value) => {
        switch (key) {
            case 'Employee':
                filter.current[key] = value?.target?.value;
                break;
            case 'OrgchartID':
                filter.current[key] = value ?? [];
                break;
            case 'AttendanceDateFrom':
                filter.current[key] = value?.value ?? null;
                setAttendanceDateFrom(value?.value ?? null);
                break;
            case 'AttendanceDateTo':
                filter.current[key] = value?.value ?? null;
                setAttendanceDateTo(value?.value ?? null);
                break;
            default:
                break;
        }
    };

    const onChangePage = (page) => {
        changePage.current = true;
        filter.current.Skip = page * (filter.current?.Limit ?? 20);
        loadData();
    };

    const onChangePerPage = (per) => {
        changePage.current = true;
        filter.current.Skip = 0;
        filter.current.Limit = per;
        loadData();
    };

    const onSelectionChanged = (e) => {
        let tmpSelectedRowKeys = [...selectedRowKeys];
        let tmpSelectedRowDatas = [...(selectedRowDatas.current ?? [])];

        if (e?.currentDeselectedRowKeys?.length > 0) {
            if (changePage.current && !isShowSelected) {
                changePage.current = false;
            } else {
                e.currentDeselectedRowKeys.forEach((key) => {
                    tmpSelectedRowKeys = tmpSelectedRowKeys.filter((i) => i !== key);
                    tmpSelectedRowDatas = tmpSelectedRowDatas.filter((i) => i.TransID !== key);
                });
            }
        }

        if (e?.currentSelectedRowKeys?.length > 0) {
            e.selectedRowsData.forEach((data) => {
                const idxKey = tmpSelectedRowKeys.indexOf(data?.TransID ?? '');
                const idxData = tmpSelectedRowDatas.findIndex((i) => i.TransID === (data?.TransID ?? ''));
                if (idxKey === -1) {
                    tmpSelectedRowKeys.push(data?.TransID ?? '');
                }
                if (idxData === -1) {
                    tmpSelectedRowDatas.push(data);
                }
            });
            changePage.current = false;
        }

        setSelectedRowKeys(tmpSelectedRowKeys);
        selectedRowDatas.current = tmpSelectedRowDatas;
    };

    const onContentReady = (e) => {
        const sltRowKeys = e?.component?.getSelectedRowKeys();
        if (changeShow.current && JSON.stringify(sltRowKeys) === JSON.stringify(selectedRowKeys)) {
            setTimeout(() => {
                changePage.current = false;
                changeShow.current = false;
            }, 50);
        }
    };

    const save = (mode) => {
        if (mode === undefined) return false;
        if (selectedRowDatas.current?.length > 0) {
            Config.popup.show('yesno', Config.lang(`Ban_co_chac_chan_muon_${mode ? 'tu_choi' : 'duyet'}`), () => saveData(mode));
        } else {
            Config.popup.show('warning', Config.lang('Ban_chua_chon_nhan_vien'));
        }
    };

    const saveData = (mode) => {
        if (mode === undefined) return false;
        selectedRowDatas.current = selectedRowDatas.current.map((item) => {
            item.IsUsed = 1;
            return item;
        });
        const params = {
            Mode: mode,
            Data: JSON.stringify(selectedRowDatas.current ?? []),
        };
        setLoading(true);
        dispatch(
            W29F2017Actions.save(params, (err, data) => {
                setLoading(false);
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup.show('error', msg);
                }
                if (data?.Status === 0) {
                    Config.notify.show('success', Config.lang(`${mode ? 'Tu_choi' : 'Duyet'}_thanh_cong`), 2000);
                    loadData();
                } else if (data?.Status === 1) {
                    Config.notify.show('error', Config.lang(`${mode ? 'Tu_choi' : 'Duyet'}_that_bai`), 2000);
                }
            })
        );
    };

    return (
        <>
            <LoadPanel
                shadingColor={'rgba(0,0,0,0.4)'}
                position={{ my: 'center', of: '#root' }}
                visible={loading}
                showIndicatorOrgchartID
                shadingOrgchartID
                showPaneOrgchartID
            />
            <Modal open={open} maxWidth={'lg'} fullWidth>
                <Modal.Title disableTypography>
                    <ActionToolbar
                        alignment={'flex-end'}
                        title={Config.lang('Duyet_gio_tang_ca_thuc_te_hang_loat')}
                        showBorder={false}
                        upperCase={false}
                        className={`${classes.actionToolbar}`}
                    >
                        <Button
                            className={`mgr5 ${classes.textUppercase}`}
                            color={'primary'}
                            viewType={'outlined'}
                            text={Config.lang('Dong1')}
                            onClick={() => handleOpenW29F2017()}
                        />
                        <Button
                            className={`mgr5 ${classes.textUppercase}`}
                            color={'primary'}
                            viewType={'filled'}
                            text={Config.lang('Duyet')}
                            startIcon={'Approval'}
                            onClick={() => save(0)}
                        />
                        <Button
                            className={`${classes.textUppercase}`}
                            color={'danger'}
                            viewType={'filled'}
                            text={Config.lang('Tu_choi')}
                            startIcon={'Cancel'}
                            onClick={() => save(1)}
                        />
                    </ActionToolbar>
                </Modal.Title>
                <Divider />
                <Modal.Content>
                    <Row>
                        <Col xs={12} sm={12} md={5} lg={4} className={`${classes.panel} ${classes.leftCol}`}>
                            <Row>
                                <ActionToolbar
                                    alignment={'space-between'}
                                    showBorder={false}
                                    upperCase={false}
                                    className={`${classes.actionToolbarFilter}`}
                                >
                                    <div className={`${classes.flex} ${classes.alignCenter} ${classes.justifyCenter} ${classes.gap10}`}>
                                        <IconButton onClick={() => collapsePanel()}>
                                            <Icon className={`${classes.pointerCursor}`} name={'Filter'} width={24} height={24} />
                                        </IconButton>
                                        {!minimum && <Typography type={'h1'}>{Config.lang('Tieu_chi_loc')}</Typography>}
                                    </div>
                                    {!minimum && (
                                        <IconButton onClick={() => collapsePanel()}>
                                            <Icon className={`${classes.pointerCursor}`} name={'ArrowLeft'} width={24} height={24} />
                                        </IconButton>
                                    )}
                                </ActionToolbar>
                            </Row>
                            <div className={`${classes.panelForm}`}>
                                <div className={`${classes.mgb15}`}>
                                    <div className={`${classes.flex} ${classes.alignCenter} ${classes.justifyBetween}`}>
                                        <Checkbox
                                            checked={isShowSelected}
                                            label={Config.lang('Hien_thi_du_lieu_da_chon')}
                                            color={'primary'}
                                            onChange={changeTypeShow}
                                        />
                                        <Typography type={'p1'}>{`(${selectedRowKeys?.length ?? 0})`}</Typography>
                                    </div>
                                </div>
                                <div className={`${classes.mgb15}`}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <label className={`${classes.mgb0} ${classes.textUppercase}`}>{Config.lang('Nhan_vien')}</label>
                                            <TextInput
                                                className={`${classes.mgb0}`}
                                                startIcon={'Search'}
                                                placeholder={Config.lang('Ma_nhan_vien_Ten_nhan_vien')}
                                                viewType={'outlined'}
                                                value={filter.current?.Employee ?? ''}
                                                onChange={(e) => handleChange('Employee', e)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`${classes.mgb15}`}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <label className={`${classes.mgb0} ${classes.textUppercase}`}>{Config.lang('Co_cau_to_chuc')}</label>
                                            <DropDownOrganization
                                                className={`${classes.mg0}`}
                                                placeholder={Config.lang('Co_cau_to_chuc')}
                                                showClearButton
                                                selectionMode={'multiple'}
                                                typeDefault={'organizational'}
                                                value={filter?.current?.OrgchartID}
                                                onValueChanged={(e, data) => handleChange('OrgchartID', data)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`${classes.mgb15}`}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <label className={`${classes.mgb0} ${classes.textUppercase}`}>{Config.lang('Ngay_tang_ca')}</label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <div className={`${classes.flex} ${classes.alignCenter}`}>
                                                <label
                                                    className={`${classes.mgb0} ${classes.mgr5} ${classes.textUppercase}`}
                                                    style={{ minWidth: 40 }}
                                                >
                                                    {Config.lang('Tu')}
                                                </label>
                                                <DatePicker
                                                    className={`${classes.datePicker}`}
                                                    actionIconAt={'start'}
                                                    max={attendanceDateTo}
                                                    placeholder={'DD/MM/YYYY'}
                                                    displayFormat={'DD/MM/YYYY'}
                                                    value={filter?.current?.AttendanceDateFrom ?? ''}
                                                    viewType={'outlined'}
                                                    onChange={(e) => handleChange('AttendanceDateFrom', e)}
                                                    clearAble
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6} className={`${classes.colDateTo}`}>
                                            <div className={`${classes.flex} ${classes.alignCenter}`}>
                                                <label
                                                    className={`${classes.mgb0} ${classes.mgr5} ${classes.textUppercase}`}
                                                    style={{ minWidth: 40 }}
                                                >
                                                    {Config.lang('Den')}
                                                </label>
                                                <DatePicker
                                                    className={`${classes.datePicker}`}
                                                    actionIconAt={'start'}
                                                    min={attendanceDateFrom}
                                                    placeholder={'DD/MM/YYYY'}
                                                    displayFormat={'DD/MM/YYYY'}
                                                    value={filter?.current?.AttendanceDateTo ?? ''}
                                                    viewType={'outlined'}
                                                    onChange={(e) => handleChange('AttendanceDateTo', e)}
                                                    clearAble
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`${classes.flex} ${classes.alignCenter} ${classes.justifyEnd}`}>
                                    <Button
                                        className={`${classes.textUppercase}`}
                                        color={'primary'}
                                        viewType={'filled'}
                                        text={Config.lang('Tim_kiem')}
                                        startIcon={'Search'}
                                        onClick={search}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={7} lg={8} className={`${classes.panel} ${classes.rightCol}`}>
                            <GridContainer
                                ref={gridRef}
                                totalItems={dataGrid?.total ?? 0}
                                itemPerPage={filter.current?.Limit}
                                skipPerPage={filter.current?.Skip}
                                listPerPage={[10, 20, 40, 50]}
                                dataSource={dataGrid?.rows ?? []}
                                disabled={loading}
                                keyExpr={'TransID'}
                                gridProps={{
                                    style: {
                                        minHeight: 400,
                                    },
                                }}
                                pagerFullScreen={false}
                                showBorders={false}
                                columnAutoWidth
                                typeShort={window.innerWidth < 768}
                                loadPanel={{
                                    enabled: loading,
                                }}
                                height={'calc(100vh - 180px)'}
                                selection={{
                                    allowSelectAll: true,
                                    mode: 'multiple',
                                    selectAllMode: 'allPages',
                                    showCheckBoxesMode: 'always',
                                }}
                                allowColumnResizing
                                typePaging={'remote'}
                                selectedRowKey={selectedRowKeys}
                                onChangePage={onChangePage}
                                onChangePerPage={onChangePerPage}
                                onSelectionChanged={onSelectionChanged}
                                onContentReady={onContentReady}
                            >
                                <Column
                                    caption={Config.lang('Ma_nhan_vien')}
                                    dataField={'EmployeeID'}
                                    alignment={'left'}
                                    fixed
                                    allowFixing
                                    width={150}
                                />
                                <Column caption={Config.lang('Ten_nhan_vien')} dataField={'EmployeeName'} fixed allowFixing />
                                <Column caption={Config.lang('Ngay_tang_ca')} dataField={'AttendanceDate'} dataType={'date'} format={'dd/MM/yyyy'} />
                                <Column caption={`${Config.lang('Vao_ra')} 1`} dataField={'InOut1'} />
                                <Column caption={`${Config.lang('Vao_ra')} 2`} dataField={'InOut2'} />
                                <Column caption={`${Config.lang('Vao_ra')} 3`} dataField={'InOut3'} />
                                <Column caption={`${Config.lang('Vao_ra')} 4`} dataField={'InOut4'} />
                                <Column caption={`${Config.lang('Vao_ra')} 5`} dataField={'InOut5'} />
                            </GridContainer>
                        </Col>
                    </Row>
                </Modal.Content>
            </Modal>
        </>
    );
};

W29F2017.propTypes = {
    open: PropTypes.bool,
    handleOpenW29F2017: PropTypes.func,
};

W29F2017.defaultProps = {
    open: false,
    handleOpenW29F2017: null,
};

export default W29F2017;
