/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/1/2020
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import * as W25F2018Actions from "../../../../redux/W2X/W25F2018/W25F2018_actions";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Column} from 'devextreme-react/data-grid';
import {Row, Col, FormGroup} from "react-bootstrap"
import GridContainer from "../../../grid-container/grid-container";
import withStyles from "@material-ui/core/styles/withStyles";
import * as generalActions from "../../../../redux/general/general_actions";
import Icons from "../../../common/icons/";
import moment from "moment";
import UserImage from "../../../common/user/user-image";
import 'moment/locale/vi';
import W25F2022Popup from "../W25F2022/W25F2022";
import {browserHistory} from "react-router";
import _ from "lodash";
import {Button, Avatar} from "diginet-core-ui/components";

const styles = () => {
    return {
        imgAvatar: {
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
        },
        styleBorder: {
            borderRight: "1px solid #7F828E"
        },
        imageRounded: {
            height: 42,
            width: 42,
            "& > img": {
                objectFit: "cover",
                height: 42,
                width: 42,
            },
        },
        textBlue: {
            color: "#0095FF"
        },
        textGray: {
            color: "#7F828E",
            fontSize: 14,
            fontWeight: 400
        },
        textTitle: {
            paddingLeft: "8px",
            fontWeight: 500,
            fontSize: 16
        },
        iconGroup: {
            width: 20,
            padding: 0
        },
        focusedRow: {
            '& .dx-datagrid-content': {
                '& tr.dx-selection > td': {
                    backgroundColor: "transparent !important"
                }
            }
        },
        iconSmall: {
            fontSize: 14,
            marginRight: 10,
        },
        imgCadidatePicture: {
            borderRadius: "50%",
            width: "40",
            height: "40"
        }
    }
};

class W25F2018 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            gridLoading: false,
            UpdatedInterviewConfirm: "",
            UpdatedInterviewStatus: "",
            rowData: null,
            mode: "",
            showW25F2022Popup: false,
            interviewDateFrom: null,
            interviewDateTo: null,
            candidateID: "",
            canConfirmStatusID: "",
            interviewStatusID: "",
            interviewTypeID: "",
            stageID: "",
            interviewerID: "",
        };
        this.filter = {
            limit: 10,
            skip: 0
        };
        this.dataGrid = null;
        this.nameDay = "";
    }

    loadListCandicate = () => {
        const {RecInfoID} = this.props;
        const { interviewDateFrom, interviewDateTo, interviewerID,  candidateID, canConfirmStatusID, stageID,
                  interviewStatusID, interviewTypeID  } = this.state;
        const params = {
            FormID: "W25F2303",
            Mode: 2,
            RecInfoID: RecInfoID ?? "",
            Language: Config.language || 84,
            CandidateID: candidateID ?? "",
            CanConfirmStatusID: canConfirmStatusID ?? "",
            InterviewStatusID: interviewStatusID ?? "",
            StageID: stageID ?? "",
            InterviewType: interviewTypeID ?? "",
            InterviewerID: interviewerID ?? "",
            InterviewDateFrom: interviewDateFrom || null,
            InterviewDateTo: interviewDateTo || null,
            skip: this.filter.skip,
            limit: this.filter.limit,
        };
        this.setState({gridLoading: true});
        this.props.w25F2018Actions.getListCandicate(params,(errors) => {
            this.setState({gridLoading: false});
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    updateDataInterviewConfirm = (dataRow = {}, CanConfirmStatusID) => {
        const {data} = dataRow;
        const params = {
            CanConfirmStatusID: CanConfirmStatusID ? CanConfirmStatusID : "",
            RecInfoID: data && data.RecInfoID ? data.RecInfoID : "",
            // RecInfoID: "46765BD2-56F7-43BC-81B1-9AF2912D846F",
            InterviewFileID: data && data.InterviewFileID ? data.InterviewFileID : "",
            CandidateID: data && data.CandidateID ? data.CandidateID : "",
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_cap_nhat?"), () => {
                this.props.w25F2018Actions.updateInterviewConfirm(params, (error) => {
                    if (error) {
                        Config.popup.show('ERROR', error);
                        return false;
                    } else {
                        Config.notify.show("success", Config.lang("Cap_nhat_thanh_cong"), 2000);
                        if (this.dataGrid) {
                            this.dataGrid.instance.cellValue(dataRow.rowIndex, "CanConfirmStatusID", CanConfirmStatusID);
                            this.dataGrid.instance.saveEditData();
                        }
                    }
                });
            }
        );

    };

    updateDataInterviewStatus = (dataRow = {}, InterviewStatusID) => {
        const {data} = dataRow;
        const param = {
            InterviewStatusID: InterviewStatusID ? InterviewStatusID : "",
            RecInfoID: data && data.RecInfoID ? data.RecInfoID : "",
            // RecInfoID: "46765BD2-56F7-43BC-81B1-9AF2912D846F",
            InterviewFileID: data && data.InterviewFileID ? data.InterviewFileID : "",
            CandidateID: data && data.CandidateID ? data.CandidateID : "",
        };

        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_cap_nhat?"), () => {
                this.props.w25F2018Actions.updateInterviewStatus(param, error => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    } else {
                        Config.notify.show("success", Config.lang("Cap_nhat_thanh_cong"), 2000);
                        if (this.dataGrid) {
                            this.dataGrid.instance.cellValue(dataRow.rowIndex, "InterviewStatusID", InterviewStatusID);
                            this.dataGrid.instance.saveEditData();
                        }
                    }
                });

            }
        );

    };

    setDataFilter = () => {
        const {dataFilter} = this.props;
        this.setState({
            candidateID: dataFilter?.CandidateID ?? "",
            canConfirmStatusID: dataFilter?.CanConfirmStatusID ?? "",
            interviewStatusID: dataFilter?.InterviewStatusID ?? "",
            stageID: dataFilter?.StageID ?? "",
            interviewTypeID: dataFilter?.InterviewTypeID ?? "",
            interviewerID: dataFilter?.InterviewerID ?? "",
            interviewDateFrom: dataFilter?.InterviewDateFrom || null,
            interviewDateTo: dataFilter?.InterviewDateTo || null,
        });
    };
    componentDidMount = () => {
        this.loadListCandicate();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.dataFilter) !== JSON.stringify(prevProps.dataFilter)) {
            this.setDataFilter();
        }
    }

    redirectScreen = (e = "") => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W25F2033",
            state: {
                CandidateID: _.get(e, "CandidateID", ""),
                RecInfoID: _.get(e, "RecInfoID", ""),
                InterviewFileID: _.get(e, "InterviewFileID", ""),
                RecInfoTitle: _.get(this.props, "RecInfoTitle", ""),
                ScreenID: "W25F2015"
            },
        });
    };

    renderEmpProfile = e => {
        const {classes} = this.props;
        const {data} = e.row;
        const candidatePictureURL = _.get(data, "CandidatePictureURL", "");
        const getLinkCandidatePictureURL = candidatePictureURL?.indexOf('http') < 0 ?
            Config.getCDNPath() + candidatePictureURL : candidatePictureURL;
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div>
                    {
                        (candidatePictureURL) ? (
                            <Avatar
                                clearAble={false}
                                width={42}
                                height={42}
                                readOnly
                                src={getLinkCandidatePictureURL}
                            />
                        ) : (
                            <Avatar
                                clearAble={false}
                                width={42}
                                height={42}
                                readOnly
                                src={require("../../../../assets/images/general/user_default.svg")}
                            />
                        )
                    }
                </div>
                <div className={"w75f2000_info"}>
                    <div className={"date-text-info"}>
                        { /* eslint-disable */}
                        <a className={[classes.textBlue, classes.textTitle].join(" ")}
                           onClick={() => this.redirectScreen(data)}>{data.CandidateName || ""}</a>
                        { /* eslint-enable */}
                    </div>
                    <div className={["date-text-info", classes.textGray].join(" ")} style={{paddingLeft: 8}}>
                        {data.SexName && data.SexName + ', '}{data.BirthDate && Config.convertDate(data.BirthDate, "", "DD/MM/YYYY", true)}
                    </div>
                </div>
            </div>
        );
    };

    renderButtonConfirm = (e) => {
        const {classes} = this.props;
        const {data} = e;
        return (
                <div className={"display_row"}>
                    <Button
                        size={"small"}
                        viewType={"text"}
                        color={data && data.CanConfirmStatusID.toLocaleUpperCase() === "YES" ? "success" : "default"}
                        label={"Yes"}
                        startIcon={"Approval"}
                        onClick={() => this.updateDataInterviewConfirm(e, "YES")}
                    />
                    <span className={classes.styleBorder}/>
                    <Button
                        size={"small"}
                        viewType={"text"}
                        color={data && data.CanConfirmStatusID.toLocaleUpperCase() === "MAYBE" ? "warning" : "default"}
                        label={"MayBe"}
                        startIcon={"QuestionMark"}
                        onClick={() => this.updateDataInterviewConfirm(e, "MayBe")}
                    />
                    <span className={classes.styleBorder}/>
                    <Button
                        size={"small"}
                        viewType={"text"}
                        color={data && data.CanConfirmStatusID.toLocaleUpperCase() === "NO" ? "danger" : "default"}
                        label={"No"}
                        startIcon={"Cancel"}
                        onClick={() => this.updateDataInterviewConfirm(e, "No")}
                    />
                </div>
        )
    };

    renderStatus = (e) => {
        const {classes} = this.props;
        const {data} = e;
        return (
                <div className={"display_row"}>
                    <Button
                        size={"small"}
                        color={data && data.InterviewStatusID.toLocaleUpperCase() === "SCHEDULED" ? "warning" : "default"}
                        label={"Scheduled"}
                        onClick={() => this.updateDataInterviewStatus(e, "SCHEDULED")}
                    />
                    <span className={classes.styleBorder}/>
                    <Button
                        size={"small"}
                        color={data && data.InterviewStatusID.toLocaleUpperCase() === "FINISHED" ? "success" : "default"}
                        label={"Finished"}
                        onClick={() => this.updateDataInterviewStatus(e, "FINISHED")}
                    />
                    <span className={classes.styleBorder}/>
                    <Button
                        size={"small"}
                        color={data && data.InterviewStatusID.toLocaleUpperCase() === "CANCEL" ? "danger" : "default"}
                        label={"Cancel"}
                        onClick={() => this.updateDataInterviewStatus(e, "CANCEL")}
                    />
                </div>
        )
    };

    renderInfo = (e) => {
        if (!e) return false;
        const {classes} = this.props;
        const {data} = e;
        return (
            <div className={"display_col pdt10 pdb10"}>
                <div className={"display_row align-center"}>
                    <Icons style={{paddingRight: "8px"}} width={14} height={14} name={"phone"}/>
                    <div>
                        {data.Mobile || ""}
                    </div>
                </div>
                <div className={"display_row align-center"}>
                    <Icons className={"fal fa-envelope " + classes.iconSmall}/>
                    <div>
                        {data.Email || ""}
                    </div>
                </div>
            </div>
        );
    };

    renderInterviewDate = (e) => {
        if (!e) return false;
        const {classes} = this.props;
        const {data} = e;
        return (
            <div className={"display_col pdt10 pdb10"}>
                <div className={"display_row align-center"}>
                    <Icons style={{paddingRight: "8px"}} width={"14px"} height={"14px"} name={"calendar"}/>
                    <div>
                        {data && data.InterviewDate && Config.convertDate(data.InterviewDate, "", "DD/MM/YYYY", true)}
                    </div>
                </div>
                <div className={"display_row align-center"}>
                    <Icons className={"far fa-clock " + classes.iconSmall}/>
                    <div>
                        {data && data.InterviewDate && Config.convertDate(data.InterviewDate, "", "LT", true)}
                    </div>
                </div>
            </div>
        );
    };

    renderInterviewPlace = (e) => {
        if (!e) return false;
        const {classes} = this.props;
        const {data} = e;
        return (
            <div className={"display_col pdt10 pdb10"}>
                <div className={"display_row align-center"}>
                    <Icons className={"fal fa-map-marker-alt " + classes.iconSmall}/>
                    <div>
                        {data.InterviewPlace || ""}
                    </div>
                </div>
            </div>
        )
    };

    renderButtonCV = (e) => {
        return (
            <Button
                viewType={"text"}
                color={"info"}
                onClick={() => this.showCV(e)}
                label={Config.lang("xem_CV")}
            />
        )
    };

    renderInterViewImage = (e) => {
        const {data} = e;
        let listInterview = data && data.InterviewerList.split(",");
        listInterview = listInterview.map(item => item.trim());
        const listUsers = Config.getListUser(listInterview, "UserID");
        return (
            <div className={"display_row align-left"}>
                {
                    listUsers.map((data, index) => {
                        return (
                            <UserImage width={"24px"} height={"24px"}  allowHover={true} key={index} data={data}/>
                        )
                    })
                }
            </div>
        )

    };

    showCV = (e) => {
        const {data} = e.row;
        this.setState({
            rowData: data,
            mode: "popup",
            showW25F2022Popup: true
        });
    };

    onCloseModal = () => {
        this.setState({
            showW25F2022Popup: false
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadListCandicate();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadListCandicate();
    };

    render() {
        let {classes, getListCandicates} = this.props;
        const {gridLoading, showW25F2022Popup, rowData} = this.state;
        const {InterviewFileID} = rowData ? rowData : "";
        // group data grid by RecInfoID day
        _.get(getListCandicates, "rows", []).forEach(d => {
            d.GroupID = moment(d.InterviewDate) >= moment() ? moment(d.InterviewDate).format("DD/MM/YYYY") : null;
        });
        const dataParam = {
            CandidateID: rowData?.CandidateID,
            RecInfoID: rowData?.RecInfoID,
            InterviewFileID: rowData?.InterviewFileID
        };
        return (
            <React.Fragment>
                <W25F2022Popup
                    openPopup={showW25F2022Popup}
                    data={rowData}
                    dataParam={dataParam}
                    keyID={InterviewFileID}
                    onClosePopup={this.onCloseModal}
                />
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                reference={ref => this.dataGrid = ref}
                                style={{border: 'none', paddingBottom: 37}}
                                dataSource={_.get(getListCandicates, "rows", [])}
                                totalItems={_.get(getListCandicates, "total", 0)}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid() - 54}
                                elementAttr={{
                                    class: classes.focusedRow
                                }}
                                typePaging={"remote"}
                                loading={gridLoading}
                                rowAlternationEnabled={false}
                                showRowLines={true}
                                showBorders={false}
                                showColumnLines={false}
                                noDataText={Config.lang("No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                            >
                                <Column
                                    dataField={'GroupID'}
                                    groupIndex={0}
                                    allowSorting={true}
                                    sortOrder={"desc"}
                                    groupCellTemplate={(cellElement, cellInfo) => {
                                        let elements = document.getElementsByClassName("dx-datagrid-group-opened");
                                        for (let i = 0; i < elements.length; i++) {
                                            elements[i].style.display = "none";
                                        }
                                        let date = (cellInfo && cellInfo.value && moment(cellInfo.value, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss")) || null;
                                        this.nameDay = (date && moment(date).locale(Config.getLocalStorage("langDHR")).format('dddd')) || "";
                                        const htmlInterview =
                                            `<div>
                                                    <span>
                                                         <Image class=${classes.iconGroup} src=${require('../../../../assets/images/icon-calendar.svg')} />
                                                    </span>
                                                    <span style="margin-left: 10px">${`${cellInfo.text} `}</span>
                                                    <span style="text-transform: capitalize">${"(" + this.nameDay + ")"}</span>
                                                </div>`;
                                        const htmlInterviewed =
                                            `<div>
                                                      <span>
                                                         <Image class=${classes.iconGroup} src=${require('../../../../assets/images/icon-calendar.svg')} />
                                                      </span><span style="margin-left: 10px">${`${Config.lang("Da_dien_ra")} `}</span>
                                                </div>`;

                                        return cellElement.innerHTML = cellInfo.displayValue  ? htmlInterview : htmlInterviewed;
                                    }}
                                />
                                <Column
                                    caption={Config.lang("Ung_vien")}
                                    dataField={'CandidateName'}
                                    alignment={"left"}
                                    cellRender={this.renderEmpProfile}
                                />
                                <Column
                                    caption={Config.lang("Thong_tin_lien_he")}
                                    dataField={"Email"}
                                    cellRender={this.renderInfo}
                                    width={220}
                                />
                                <Column
                                    caption={Config.lang("Ung_vien_xac_nhan")}
                                    alignment={"center"}
                                    dataField={"CanConfirmStatusID"}
                                    width={250}
                                    cellRender={this.renderButtonConfirm}
                                />
                                <Column
                                    caption={Config.lang("Trang_thai")}
                                    alignment={"center"}
                                    dataField={"InterviewStatusID"}
                                    width={260}
                                    cellRender={this.renderStatus}
                                />
                                <Column
                                    caption={Config.lang("Giai_doan")}
                                    alignment={"left"}
                                    dataField={"StageName"}
                                    width={250}
                                />
                                <Column
                                    caption={Config.lang("Loai_phong_van")}
                                    alignment={"left"}
                                    dataField={'InterviewType'}
                                />
                                <Column
                                    caption={Config.lang("Thoi_gian")}
                                    dataField={"InterviewDate"}
                                    dataType={"date"}
                                    format={"dd/MM/yyyy"}
                                    editorOptions={{
                                        placeholder: "DD/MM/YYYY"
                                    }}
                                    alignment={"left"}
                                    width={150}
                                    cellRender={this.renderInterviewDate}

                                />
                                <Column
                                    caption={Config.lang("Dia_diem")}
                                    alignment={"left"}
                                    dataField={"InterviewPlace"}
                                    cellRender={this.renderInterviewPlace}
                                />
                                <Column
                                    caption={Config.lang("Nguoi_phong_van")}
                                    dataField={"InterviewerList"}
                                    cellRender={this.renderInterViewImage}
                                />
                                <Column
                                    caption={Config.lang("xem_CV")}
                                    alignment={"center"}
                                    width={150}
                                    cellRender={this.renderButtonCV}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect((state) => ({
    getListCandicates: state.W25F2018.getListCandicates,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w25F2018Actions: bindActionCreators(W25F2018Actions, dispatch)
}), null, {forwardRef: true}), withStyles(styles))(W25F2018);
